import Axios from "axios";
import { BASE_URL, SHOW_NOTIFICATION, UPLOAD_FILES } from "../constants";

export const processDocuments = (body, establishment, path) => {
  return async (dispatch) => {
    await Axios.post(
      `${BASE_URL}${UPLOAD_FILES}/${establishment}/${path}`,
      body
    )
      .then((response) => {
        if (response?.data) {
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              show: true,
              status: "info",
              message: "Ficheros enviados.",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Error al enviar ficheros.",
          },
        });

        console.log("err-->", err);
      });
  };
};
