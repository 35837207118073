import { createSelector } from "reselect";

export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

export const getEstablishment = createSelector(
  (state) => state.establishment,
  (establishment) => establishment
);

export const getPath = createSelector(
  (state) => state.path,
  (path) => path
);
