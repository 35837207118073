import { handleActions } from "redux-actions";

import {
  CLOSE_NOTIFICATION,
  SET_ESTABLISHMENT,
  SHOW_NOTIFICATION,
  SET_PATH,
} from "../constants";

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

export const establishment = handleActions(
  {
    [SET_ESTABLISHMENT]: (state, action) => action.payload,
  },
  null
);

export const path = handleActions(
  {
    [SET_PATH]: (state, action) => action.payload,
  },
  null
);
