import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import NotificationsHandler from "./Notifications";

import "antd/dist/antd.css";
import "./styles/index.less";

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={es_ES}>
      <App />
      <NotificationsHandler />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
