import { MultipleDragComponent } from "./styles";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { processDocuments } from "./actions";
import { useEffect } from "react";
import history from "./history";
import { getEstablishment, getPath } from "./selectors";
import { SET_ESTABLISHMENT, SET_PATH } from "./constants";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const establishment = useSelector(getEstablishment);
  const path = useSelector(getPath);

  async function readFileAsDataURL(file) {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    return result_base64;
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      const doc = [];
      for (let index = 0; index < acceptedFiles.length; index++) {
        const acceptedFile = acceptedFiles[index];
        let dataURL = await readFileAsDataURL(acceptedFile);
        doc.push({
          base64: dataURL.split("base64,")[1],
          fileName: acceptedFile.name,
        });
      }
      await dispatch(processDocuments(doc, establishment, path));
      setLoading(false);
    },
    [dispatch, establishment, path]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...(isDragActive ? { borderColor: "#2196f3" } : {}),
      ...(isDragAccept ? { borderColor: "#00e676" } : {}),
      ...(isDragReject ? { borderColor: "#ff1744" } : {}),
    }),
    [isDragAccept, isDragReject, isDragActive]
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (!establishment || !path) {
      try {
        const objects = history.location.pathname.split("/")[1].split(";");
        const name = objects[0];
        const path = objects[1];
        dispatch({ type: SET_ESTABLISHMENT, payload: name });
        dispatch({ type: SET_PATH, payload: path });
      } catch {}
    }
  }, [dispatch, establishment, path]);

  return (
    <div>
      <Spin spinning={loading} indicator={antIcon}>
        <MultipleDragComponent {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <i className="fas fa-file-upload fa-7x"></i>
          <br></br>
          <p>
            Arrastre y suelte los archivos, o haga clic para seleccionar-los.
          </p>
        </MultipleDragComponent>
      </Spin>
    </div>
  );
}

export default App;
